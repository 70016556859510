import { useNavigate } from "react-router-dom"
import { Layout } from "../../../ui/layout"
import Button from "../../../ui/molecules/button"
import { MenuItems } from "../../../ui/organisms/navbar/dto"
import { HorizontalTab } from "../../../ui/organisms/horizontalTab"
import { Tab } from "react-tabs"
import clsx from "clsx"
import { useState } from "react"
import { CustomerList } from "./customerList"
import { AllCustomersList } from "./list"

export function Segnalazioni() {
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState<number>(0)


    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Controllo', 'Segnalazioni']}
                noPaddingLat
                headerLabel="Segnalazioni"
                headerChildren={
                    <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Crea nuovo" color={"orange"}
                            onClick={() => {
                                navigate('/addSegnalazione')
                            }}
                        />

                    </div>
                }
            >
                <HorizontalTab
                    children={[
                        <>
                            <Tab
                                onClick={() => { 
                                    setSelectedTab(0)
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                        "text-label-sm text-neutral-600": selectedTab !== 0
                                    })
                                }
                                tabIndex={"0"}
                            >
                                Elenco cliente
                            </Tab>
                            <Tab
                                onClick={() => {
                                    setSelectedTab(1)
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                        "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                    }
                                    )
                                }
                                tabIndex={"1"}
                            >
                                Elenco completo
                            </Tab>
                        </>
                    ]}
                />
                <div className="fieldset bg-brandPrimary-200">
                {
                        selectedTab === 0 &&
                        <CustomerList />
                    }
                    {
                        selectedTab === 1 &&
                        <AllCustomersList />
                    }
                </div>
            </Layout>
    )
}