import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../lib/redux/hooks'
import { Calendar } from '../../../../../ui/molecules/calendar'
import { SelectCustom } from '../../../../../ui/molecules/select'
import TextArea from '../../../../../ui/molecules/textArea'
import { normativa, yearOptions } from '../../../../../utils'
import { resetPACCreationRequest, setPACCreationEndingDate, setPACCreationNorm, setPACCreationNote, setPACCreationStartingDate, setPACCreationUserId, setPACCreationYear } from '../../slice'
import '../../styles.scss'

export function GestionePACAddForm() {
    const dispatch = useAppDispatch()
    const pacState = useAppSelector(state => state.pac)
    const userState = useAppSelector(state => state.users)

    useEffect(() => {
        dispatch(resetPACCreationRequest())
        dispatch(setPACCreationUserId(userState.findMeResponse?.id))
    }, [])

    return (
        <div>
            <div className="pac-container">

                <div className='pac-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Gestione PAC </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Inserimento</span>
                    </div>
                </div>

                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Anno</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACErrors.year}
                                errorLabel='Anno mancante'
                                placeholder={'Seleziona anno...'}
                                options={yearOptions}
                                onChange={(value) => dispatch(setPACCreationYear(Number(value)))}
                            />
                        </div>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Normativa</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACErrors.norm}
                                errorLabel='Normativa mancante'
                                placeholder={'Seleziona normativa...'}
                                options={normativa}
                                onChange={(value) => dispatch(setPACCreationNorm(value))}
                            />
                        </div>
                    </div>

                    <div className='pb-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Data inizio</span>
                            </div>
                            <Calendar
                                errorLabel={pacState.PACErrors.startingDate ? 'Data inizio mancante' : undefined}
                                selected={pacState.PACCreationRequest.startingDate}
                                onChange={(date) => dispatch(setPACCreationStartingDate(date))} 
                                />
                        </div>
                        <div style={{flex: '0.5'}}>
                        <div className="text-left">
                                <span className="input-label">Data fine</span>
                            </div>
                            <Calendar
                                errorLabel={pacState.PACErrors.startingDate ? 'Data fine mancante' : undefined}
                                minDate={pacState.PACCreationRequest.startingDate}
                                selected={pacState.PACCreationRequest.endingDate}
                                onChange={(date) => dispatch(setPACCreationEndingDate(date))}  
                                />
                        </div>
                    </div>

                    <div className='pb-5 pt-5'>
                        <div className="text-left mt-3">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            onChangeText={(value) => dispatch(setPACCreationNote(value))} />
                    </div>
                </div>
            </div>
        </div>
    )
}