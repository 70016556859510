import { MultipleMonthOptions, optionsFormatter } from "../../../utils"

export const periodicityValueGenerator = (periods: number[]): string => {
    return periods.length > 1 ? periods.slice(0,-1).map(p => `${p}-`).join('')
    + String(periods[periods.length-1]) 
        : String(periods[0])
}

export const handlePeriodicity = (periods: number[]): string | undefined => {
    const periodString: string = periodicityValueGenerator(periods)
    return optionsFormatter(periodString,MultipleMonthOptions)
}