import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { EditIcon } from "../../../ui/icons/edit"
import { ClientBanner } from "../../../ui/molecules/clientBanner"
import { Banner } from "../../../ui/organisms/banner"
import { optionsFormatter, attivita_bio, normativa, formatterDate, PACVisitingModeOptions, PACVisitingTypeOptions } from "../../../utils"
import { useEffect } from "react"
import { GetAllPacDetails, setPACDetailClient, setPACDetailIdToUpdate, setPACDetailNorm, setPACDetailsUpdateNote, setPACDetailUpdateAnnualControlPlaneId, setPACDetailUpdateMode, setPACDetailUpdatePeriodicity, setPACDetailUpdateSampling, setPACDetailUpdateSpecifications, setPACDetailUpdateVisitingType } from "./slice"
import { handlePeriodicity } from "./utils"
import { PACDetailDTO } from "./dataService/dto"

export function PACList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const pacState = useAppSelector(state => state.pac)

    useEffect(() => {
        dispatch(GetAllPacDetails({itemsPerPage: 0, page: 0, order: true}))
    }, [])

    const onEditHandle = (pacDetail: PACDetailDTO) => {
        dispatch(setPACDetailIdToUpdate(pacDetail.id))
        dispatch(setPACDetailUpdateAnnualControlPlaneId(pacDetail.annualControlPlaneDTO.id))
        dispatch(setPACDetailNorm(pacDetail.annualControlPlaneDTO.norm))
        dispatch(setPACDetailClient(pacDetail.customerDTO.companyName))
        dispatch(setPACDetailUpdatePeriodicity(pacDetail.periodicity))
        dispatch(setPACDetailUpdateMode(pacDetail.mode))
        dispatch(setPACDetailUpdateVisitingType(pacDetail.visitType))
        dispatch(setPACDetailUpdateSampling(pacDetail.sampling))
        dispatch(setPACDetailUpdateSpecifications(pacDetail.specifications))
        dispatch(setPACDetailsUpdateNote(pacDetail.note))
        navigate('/editPacDetail')
    }

    return (
        <div style={{padding: '24px'}}>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}></th>
                        <th>Anno</th>
                        <th>Cliente</th>
                        <th>Periodo</th>
                        <th>Modalità visita</th>
                        <th>Tipo Visita</th>
                        <th>Specifiche</th>
                        <th>Prelievo</th>
                        <th>Valido dal</th>
                        <th>Valido al</th>
                        <th>Riferimento incarico</th>
                        <th>Data visita</th>
                        <th>Note</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        pacState.getAllPACDetailsResponse?.data.map((pac, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>
                                    <div style={{display: 'flex', gap: '6px'}}>
                                        <button onClick={() => onEditHandle(pac)}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                    </div>
                                </td>
                                <td>{pac.annualControlPlaneDTO.year}</td>
                                <td>{pac.customerDTO.companyName}</td>
                                <td>{handlePeriodicity(pac.periodicity)}</td>
                                <td>{optionsFormatter(pac.mode, PACVisitingModeOptions)}</td>
                                <td>{optionsFormatter(pac.visitType, PACVisitingTypeOptions)}</td>
                                <td>{pac.specifications}</td>
                                <td>{pac.sampling ? 'Sì' : 'No'}</td>
                                <td>{formatterDate(pac.annualControlPlaneDTO.startingDate)}</td>
                                <td>{formatterDate(pac.annualControlPlaneDTO.endingDate)}</td>
                                <td>DA IMPL.</td>
                                <td>DA IMPL.</td>
                                <td>{pac.note}</td>
                                <td></td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}