import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import '../style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { EditCompanyCreation, GetCorporateCenterById, GetCorporateCenterByIdEdit, addCorporateCenter, removeCorporateCenter, resetCreateCompanyCenterByIdEditStatus, resetCreateCompanyCenterByIdStatus, setActivationDateCorporateCenter, setAddressCorporateCenter, setCapCorporateCenter, setCodeCorporateCenter, setComuneCorporateCenter, setDeactivationDateCorporateCenter, setDescriptionCorporateCenter, setEmailCorporateCenter, setIdCorporateCenter, setIdsCorporateCenterResponse, setLatCorporateCenter, setLongCorporateCenter, setPecCorporateCenter, setProvinciaCorporateCenter, setRecapitoCorporateCenter, setReferenteCorporateCenter, setRegioneCorporateCenter, setSelectedCustomerId, setSelectedIDCorporateCenter } from "../slice";
import { OptionType, formatterDate } from "../../../utils";
import { SpinnerComponent } from "../../../lib/spinner";
import Input from "../../../ui/molecules/input/Input";
import { Province, Region, genreSelect, regioniProvinceMap, regions } from "../../../utils";
import { provinceComuniMap } from "../../../utils/listaComuni";
import { SelectCustom } from "../../../ui/molecules/select";
import { CalendarIcon } from "../../../ui/icons/calendar";
import TextArea from "../../../ui/molecules/textArea/TextArea";
import { PopupForm } from "../../../ui/organisms/popupForm";
import Button from "../../../ui/molecules/button";
import { format } from "date-fns";
import { Calendar } from "../../../ui/molecules/calendar";
import { LogoSpinnerComponent } from "../../../lib/spinner/spinnerLogo";


registerLocale('it', it)
interface Props {
    closeHandler: () => void;
}
export function EditSingleCentro(props: Props) {
    const { closeHandler } = props;
    const clientiState = useAppSelector(state => state.clienti)
    const usersState = useAppSelector(state => state.users)
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const regionOptions: OptionType[] = Array.from(regioniProvinceMap.keys()).map(region => ({
        value: region,
        label: region
    }));
    const [showCentriAziendali, setShowCentriAziendali] = useState<boolean>(true);
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);
    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);

    useEffect(() => {
        dispatch(GetCorporateCenterByIdEdit(clientiState.selectedIdCorporateCenter))
    }, [])

    useEffect(() => {
        if (clientiState.GetCorporateCenterByIdEditStatus === 'successfully') {
            if (clientiState.GetCorporateCenterByIdEditResponse !== undefined) {

                if (clientiState.GetCorporateCenterByIdEditResponse.countryRegion) {
                    const foundRegion = regions.find((region: Region) => region.name === clientiState.GetCorporateCenterByIdEditResponse?.countryRegion.toString());
                    if (foundRegion) {

                        const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                            value: province.code,
                            label: province.name
                        }));

                        setProvinceOptions(newProvinceOptions);
                    } else {
                        setProvinceOptions([]);
                    }
                } else {
                    setProvinceOptions([]);
                }

                if (clientiState.GetCorporateCenterByIdEditResponse?.province) {
                    const comuni = provinceComuniMap.get(clientiState.GetCorporateCenterByIdEditResponse?.province.toString()) || [];
                    const newComuneOptions = comuni.map((comune: string) => ({
                        value: comune,
                        label: comune
                    }));
                    setComuneOptions(newComuneOptions);
                } else {
                    setComuneOptions([]);
                }

                dispatch(setCodeCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.code))
                dispatch(setRegioneCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.countryRegion))
                dispatch(setProvinciaCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.province))
                dispatch(setComuneCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.city))
                dispatch(setCapCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.zipCode))
                dispatch(setAddressCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.address))
                dispatch(setReferenteCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.referrer))
                dispatch(setRecapitoCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.phone))
                dispatch(setEmailCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.email))
                dispatch(setPecCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.pec))
                dispatch(setLatCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.latitude))
                dispatch(setLongCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.longitude))
                dispatch(setActivationDateCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.activationDate))
                dispatch(setDeactivationDateCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.deactivationDate))
                dispatch(setSelectedCustomerId(clientiState.selectedCustomerId))
                dispatch(setDescriptionCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.description))
                dispatch(setIdCorporateCenter(clientiState.GetCorporateCenterByIdEditResponse.id))
            }
            dispatch(resetCreateCompanyCenterByIdEditStatus('idle'))
        }

    }, [clientiState.GetCorporateCenterByIdEditStatus])

    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    const handleComuneChange = (selectedOption: OptionType | null) => {
        setSelectedComune(selectedOption);
    };

    if (!showCentriAziendali) {
        return (
            <LogoSpinnerComponent fullScreen />
        )
    }

    return (
        <>
            <PopupForm title={'Modifica centro aziendale'} close={() => closeHandler()}>
                <div style={{ padding: '24px' }} className="bg-brandPrimary-100" >

                    <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                        <div style={{ flex: '0.3' }}>
                            <div className="text-left">
                                <span className="input-label">ID/Codice</span>
                            </div>
                            <Input
                                placeholder="ID/Codice"
                                defaultValue={clientiState.companyCenterEditCreateRequest.code}
                                value={clientiState.companyCenterEditCreateRequest.code}
                                onChangeText={(text) => dispatch(setCodeCorporateCenter(text))}
                            />
                        </div>
                        <div style={{ flex: '0.7' }}>
                            <div className="text-left">
                                <span className="input-label">Descrizione</span>
                            </div>
                            <Input
                                placeholder="Descrizione"
                                onChangeText={(text) => dispatch(setDescriptionCorporateCenter(text))}
                                defaultValue={clientiState.companyCenterEditCreateRequest.description}
                                value={clientiState.companyCenterEditCreateRequest.description}
                            />
                        </div>

                    </div>

                    <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                        <div style={{ flex: '0.5' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label">Regione</span>
                            </div>
                            <SelectCustom
                                defaultValue={clientiState.companyCenterEditCreateRequest.countryRegion}
                                value={clientiState.companyCenterEditCreateRequest.countryRegion}
                                placeholder={'Regione'}
                                options={regionOptions}
                                onChange={(value) => { handleRegionChange(value as unknown as OptionType | null); dispatch(setRegioneCorporateCenter(value)) }}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label">Provincia</span>
                            </div>
                            <SelectCustom
                                placeholder={'Provincia'}
                                options={provinceOptions}
                                defaultValue={clientiState.companyCenterEditCreateRequest.province}
                                value={clientiState.companyCenterEditCreateRequest.province}
                                onChange={(value) => { handleProvinceChange(value as unknown as OptionType | null); dispatch(setProvinciaCorporateCenter(value)) }}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label">Comune</span>
                            </div>
                            <SelectCustom
                                placeholder={'Comune'}
                                defaultValue={clientiState.companyCenterEditCreateRequest.city}
                                value={clientiState.companyCenterEditCreateRequest.city}
                                options={comuneOptions}
                                onChange={(value) => {
                                    dispatch(setComuneCorporateCenter(value))
                                }}
                            />
                        </div>

                        <div style={{ flex: '0.5' }}>
                            <Input
                                label={'CAP'}
                                placeholder="CAP"
                                onChangeText={(text) => dispatch(setCapCorporateCenter(text))}
                                defaultValue={clientiState.companyCenterEditCreateRequest.zipCode}
                                value={clientiState.companyCenterEditCreateRequest.zipCode}
                            />
                        </div>

                    </div>


                    <div className="mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                        <div style={{ flex: '1 1 auto' }}>
                            <Input
                                label={'Indirizzo'}
                                placeholder="Indirizzo"
                                onChangeText={(text) => dispatch(setAddressCorporateCenter(text))}
                                defaultValue={clientiState.companyCenterEditCreateRequest.address}
                                value={clientiState.companyCenterEditCreateRequest.address}
                            />
                        </div>

                    </div>

                    <div className="formSection mt-6">
                        <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Latitudine'}
                                    placeholder="Latitudine"
                                    onChangeText={(text) => dispatch(setLatCorporateCenter(text))}
                                    defaultValue={clientiState.companyCenterEditCreateRequest.latitude}
                                    value={clientiState.companyCenterEditCreateRequest.latitude}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Longitudine'}
                                    placeholder="Longitudine"
                                    onChangeText={(text) => dispatch(setLongCorporateCenter(text))}
                                    defaultValue={clientiState.companyCenterEditCreateRequest.longitude}
                                    value={clientiState.companyCenterEditCreateRequest.longitude}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="formSection mt-6">
                        <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Referente'}
                                    placeholder="Referente"
                                    onChangeText={(text) => dispatch(setReferenteCorporateCenter(text))}
                                    defaultValue={clientiState.companyCenterEditCreateRequest.referrer}
                                    value={clientiState.companyCenterEditCreateRequest.referrer}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Recapito'}
                                    placeholder="Recapito"
                                    onChangeText={(text) => dispatch(setRecapitoCorporateCenter(text))}
                                    defaultValue={clientiState.companyCenterEditCreateRequest.phone}
                                    value={clientiState.companyCenterEditCreateRequest.phone}
                                />
                            </div>
                        </div>
                        <div className="mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Email'}
                                    placeholder="Email"
                                    onChangeText={(text) => dispatch(setEmailCorporateCenter(text))}
                                    defaultValue={clientiState.companyCenterEditCreateRequest.email}
                                    value={clientiState.companyCenterEditCreateRequest.email}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'PEC'}
                                    placeholder="PEC"
                                    onChangeText={(text) => dispatch(setPecCorporateCenter(text))}
                                    defaultValue={clientiState.companyCenterEditCreateRequest.pec}
                                    value={clientiState.companyCenterEditCreateRequest.pec}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="formSection mt-6">
                        <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Data Ingresso</span>
                                </div>
                                <Calendar
                                    selected={clientiState.companyCenterEditCreateRequest.activationDate}
                                    onChange={(date) => {
                                        dispatch(setActivationDateCorporateCenter(format(new Date(date), 'yyyy-MM-dd')))
                                    }}
                                />

                            </div>

                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Data Uscita</span>
                                </div>
                                <Calendar
                                    selected={clientiState.companyCenterEditCreateRequest.deactivationDate}
                                    onChange={(date) => {
                                        dispatch(setDeactivationDateCorporateCenter(format(new Date(date), 'yyyy-MM-dd')))
                                    }}
                                />

                            </div>
                        </div>

                        <div className="mt-6" style={{ display: 'flex', justifyContent: 'right' }}>
                            <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Annulla" color={"dimgrey"}
                                onClick={() => { closeHandler(); }}
                            />
                            <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                                onClick={() => {
                                    dispatch(EditCompanyCreation({ id: clientiState.companyCenterEditCreateRequest.id, data: clientiState.companyCenterEditCreateRequest }))
                                    closeHandler()
                                }
                                }
                            />


                        </div>

                    </div>

                </div>
            </PopupForm>

        </>
    )
}