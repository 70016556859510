import { Backdrop } from "@mui/material"
import { LogoIcon } from "../../../ui/icons/logo/logo"
import './styles.scss'

interface Props {
    fullScreen?: boolean
}

export function LogoSpinnerComponent(props: Props) {
    const { fullScreen } = props

    if (fullScreen) {
        return (
            <div>
                <Backdrop
                    sx={{ backgroundColor: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <div>
                        <div className="loader"></div>
                    </div>
                </Backdrop>
            </div>
        )
    }

    return (
        <div>
            <div className="loader"></div>
        </div>
    )
}