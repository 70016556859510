import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import { SelectCustom } from '../../../../ui/molecules/select'
import TextArea from '../../../../ui/molecules/textArea'
import { formatterDate, MultipleMonthOptions, normativa, optionsFormatter, OptionType, PACVisitingModeOptions, PACVisitingTypeOptions, SingleMonthOptions, yearOptions } from '../../../../utils'
import '../styles.scss'
import { GetAllPac, resetGetAllPACsStatus, setPACDetailsUpdateNote, setPACDetailUpdateAnnualControlPlaneId, setPACDetailUpdateMode, setPACDetailUpdatePeriodicity, setPACDetailUpdateSampling, setPACDetailUpdateSpecifications, setPACDetailUpdateVisitingType } from '../slice'
import { periodicityValueGenerator } from '../utils'

export function EditPACForms() {
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const pacState = useAppSelector(state => state.pac)
    const [pacOptions, setPacOptions] = useState<OptionType[]>([])

    useEffect(() => {
        dispatch(GetAllPac(pacState.getAllPACsFilters))
        console.log(pacState.PACDetailNorm);
        
    }, [])

    useEffect(() => {
        if(pacState.getAllPACsStatus === 'successfully' &&
            pacState.getAllPACsResponse !== undefined
        ) {
            dispatch(resetGetAllPACsStatus())
            const data = pacState.getAllPACsResponse.data.filter(pac => pac.norm === pacState.PACDetailNorm).map(pac => {
                return {
                    value: pac.id,
                    label: `Anno rif. ${pac.year} - ${optionsFormatter(pac.norm!, normativa)} - Valido dal ${formatterDate(pac.startingDate)} al ${formatterDate(pac.endingDate)}`
                } as OptionType
            })
            setPacOptions(data)
        }
    }, [pacState.getAllPACsStatus])

    return (
        <div>
            <div className="pac-container">

                <div className='pac-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>PAC </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Riferimento PAC</span>
                    </div>
                </div>

                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div>
                        <SelectCustom
                            error={pacState.PACDetailErrors.annualControlPlaneId}
                            errorLabel='Riferimento mancante'
                            placeholder={'Seleziona riferimento...'}
                            value={pacState.PACDetailUpdateRequest.annualControlPlaneId}
                            options={pacOptions}
                            onChange={(value) => dispatch(setPACDetailUpdateAnnualControlPlaneId(value))}
                        />
                    </div>

                    {
                        false &&
                        <div className='pt-5'>
                            <div className='pac-info'>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <div>
                                        <div className='pac-title'>Anno</div>
                                        <div className='pac-label'>2025</div>
                                    </div>
                                    <div>
                                        <div className='pac-title'>Revisione</div>
                                        <div className='pac-label'>0</div>
                                    </div>
                                    <div>
                                        <div className='pac-title'>Riferimento P013</div>
                                        <div className='pac-label'>P013 rev. 16</div>
                                    </div>
                                    <div>
                                        <div className='pac-title'>Valido dal</div>
                                        <div className='pac-label'>01/01/2025</div>
                                    </div>
                                    <div>
                                        <div className='pac-title'>Valido al</div>
                                        <div className='pac-label'>31/12/2025</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>

            <div className="pac-container">  

                <div className='pac-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>PAC </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Dettagli</span>
                    </div>
                </div>
                
                <div style={{padding: '24px 24px 32px 24px'}}>

                    {
                        false && (
                            <div className='mb-5'>
                                <div className='pac-info'>
                                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                        <div>
                                            <div className='pac-title'>Protocollo Incarico</div>
                                            <div className='pac-label'>SCP20240102/0001</div>
                                        </div>
                                        <div>
                                            <div className='pac-title'>Contratto</div>
                                            <div className='pac-label'></div>
                                        </div>
                                        <div>
                                            <div className='pac-title'>Riferimento</div>
                                            <div className='pac-label'>CDX-CD09-67</div>
                                        </div>
                                        <div>
                                            <div className='pac-title'>Ispettore</div>
                                            <div className='pac-label'>GIOPR - Giovanni Previtera</div>
                                        </div>
                                        <div>
                                            <div className='pac-title'>Data Visita</div>
                                            <div className='pac-label'>31/12/2025</div>
                                        </div>
                                        <div>
                                            <div className='pac-title'>Protocollo Entrata</div>
                                            <div className='pac-label'>SCP20240102/0001</div>
                                        </div>
                                        <div>
                                            <div className='pac-title'>Prelievo Effettuato</div>
                                            <div className='pac-label'>No</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Periodo</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.periodicity}
                                errorLabel='Periodicità mancante'
                                value={periodicityValueGenerator(pacState.PACDetailUpdateRequest.periodicity!)}
                                placeholder={'Seleziona periodo...'}
                                options={pacState.PACDetailNorm !== 'NORMA_BIO' ?
                                    MultipleMonthOptions : SingleMonthOptions
                                }
                                onChange={(value) => dispatch(setPACDetailUpdatePeriodicity(value?.split('-').map(v => Number(v))))}
                            />
                        </div>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Modalità</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.mode}
                                errorLabel='Modalità mancante'
                                value={pacState.PACDetailUpdateRequest.mode}
                                placeholder={'Seleziona modalità...'}
                                options={PACVisitingModeOptions}
                                onChange={(value) => dispatch(setPACDetailUpdateMode(value))}
                            />
                        </div>
                    </div>

                    <div className='pb-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Tipo Visita</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.visitType}
                                errorLabel='Tipologia mancante'
                                value={pacState.PACDetailUpdateRequest.visitType}
                                placeholder={'Seleziona tipo...'}
                                options={PACVisitingTypeOptions}
                                onChange={(value) => dispatch(setPACDetailUpdateVisitingType(value))}
                            />
                        </div>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Prelievo</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.sampling}
                                errorLabel='Prelievo mancante'
                                value={pacState.PACDetailUpdateRequest.sampling ? 'Sì' : 'No'}
                                placeholder={'Seleziona prelievo...'}
                                options={[{value: 'Sì', label: 'Sì'}, {value: 'No', label: 'No'}]}
                                onChange={(value) => dispatch(setPACDetailUpdateSampling(value === 'Sì'))}
                            />
                        </div>
                    </div>

                    <div className='pb-5 pt-5'>
                        <div className="text-left">
                            <span className="input-label">Specifiche</span>
                        </div>
                        <TextArea
                            value={pacState.PACDetailUpdateRequest.specifications}
                            onChangeText={(value) => dispatch(setPACDetailUpdateSpecifications(value))} />
                        <div className="text-left mt-3">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={pacState.PACDetailUpdateRequest.note}
                            onChangeText={(value) => dispatch(setPACDetailsUpdateNote(value))} />
                    </div>
                </div>
            </div>
        </div>
    )
}