import { useState } from "react"
import Button from "../../ui/molecules/button"
import { SelectCustom } from "../../ui/molecules/select"
import Input from "../../ui/molecules/input/Input"

enum FilteringTypologyEnum {
    INSPECTOR = 'INSPECTOR',
    NUMBER = 'NUMBER'
}

interface PrelievoItemDTO {
    inspector: string
    number: string
    protocol: string
    status: string
    assignment: string
    visitingDate: string
    customer: string
    norm: string
}

const mock: PrelievoItemDTO[] = [
    {
        inspector: 'Alessandro Lo Giudice',
        number: '00000100',
        protocol: 'SC P20241114/0001',
        status: 'Utilizzata',
        assignment: '001',
        visitingDate: '15/01/2025',
        customer: 'MADMAX SRL',
        norm: 'Biologica'
    },
    {
        inspector: 'Isidoro Spanò',
        number: '00000101',
        protocol: 'SC P20241114/0002',
        status: 'Smarrita',
        assignment: '010',
        visitingDate: '15/01/2025',
        customer: 'MADMAX SRL',
        norm: 'Biologica'
    },
    {
        inspector: 'Alessandro Lo Giudice',
        number: '00000102',
        protocol: 'SC P20241114/0003',
        status: 'Annullata/Distrutta',
        assignment: '001',
        visitingDate: '15/01/2025',
        customer: 'MADMAX SRL',
        norm: 'Global G.A.P.'
    }
]

export function GestionePrelieviList() {
    const [filteringTypology, setFilteringTypology] = useState<FilteringTypologyEnum>(FilteringTypologyEnum.INSPECTOR)

    return (
        <div>
            <div style={{ padding: '0px 24px 0px 24px', display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'flex-end'}}>
                <div style={{flex: '0.25'}}>
                    <div className="text-left">
                            <span className="input-label">Filtra per</span>
                        </div>
                        <SelectCustom
                            value={filteringTypology}
                            placeholder={'Seleziona tipo di filtro...'}
                            options={[{value: FilteringTypologyEnum.INSPECTOR, label: 'Tecnico Ispettore'}, 
                                {value: FilteringTypologyEnum.NUMBER, label: 'Numero Busta'}]}
                            onChange={(value) => setFilteringTypology(value as FilteringTypologyEnum)}
                        />
                </div>
                {
                    filteringTypology === FilteringTypologyEnum.INSPECTOR && (
                        <div style={{flex: '0.5', display: 'flex', gap: '24px'}}>
                            <div style={{flex: '0.5'}}>
                                <div className="text-left">
                                        <span className="input-label">Tecnico Ispettore</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Seleziona ispettore...'}
                                        options={[{value: '1', label: 'Alessandro Lo Giudice'}, 
                                            {value: '2', label: 'Isidoro Spanò'}]}
                                        onChange={(value) => {}}
                                    />
                            </div>
                            <div style={{flex: '0.5'}}> 
                                <div className="text-left">
                                    <span className="input-label">Stato Buste Prelievo Campione</span>
                                </div>
                                <SelectCustom
                                    errorLabel='Stato mancante'
                                    placeholder={'Seleziona stato...'}
                                    options={[{value: '1', label: 'In Giacenza'}, 
                                        {value: '2', label: 'Consegnata'},
                                        {value: '3', label: 'Smarrita'},
                                        {value: '4', label: 'Annulata/Distrutta'}
                                    ]}
                                    onChange={(value) => {}}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    filteringTypology === FilteringTypologyEnum.NUMBER && (
                        <div style={{flex: '0.25'}}>
                            <div className="text-left">
                                <span className="input-label">Numero busta</span>
                            </div>
                            <Input
                                placeholder="Specifica n. busta..."
                                onChangeText={(text) => {}}
                            />
                        </div>
                    )
                }
                <Button
                    size={"lg"}
                    iconPosition={"off"}
                    variant={"link"}
                    label="Pulisci"
                    color={"gray"}
                    onClick={() => {}}
                />
                <Button
                    size={"lg"}
                    iconPosition={"off"}
                    variant={"outline"}
                    label="Cerca"
                    color={"blue"}
                    onClick={() => {}}
                />
            </div>
            <div style={{padding: '24px'}}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}></th>
                            <th>Ispettore</th>
                            <th>Numero Busta Prelievo Campioni</th>
                            <th>Protocollo Assegnazione</th>
                            <th>Stato</th>
                            <th>Incarico / Visita</th>
                            <th>Cliente</th>
                            <th>Normativa</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            mock.map((pre, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td></td>
                                    <td>{pre.inspector}</td>
                                    <td>{pre.number}</td>
                                    <td>{pre.protocol}</td>
                                    <td>{pre.status}</td>
                                    <td>{`Incarico: ${pre.assignment} della Visita ispettiva del ${pre.visitingDate}`}</td>
                                    <td>{pre.customer}</td>
                                    <td>{pre.norm}</td>
                                    <td></td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}