import { useEffect, useState } from "react";
import { CustomRadio } from "../../ui/molecules/radioButton";
import { SelectCustom } from "../../ui/molecules/select";
import MultiSelectCheckbox from "../../ui/molecules/multiSelectCheckbox";
import { OptionType } from "../../utils";
import Button from "../../ui/molecules/button";
import { TickIcon } from "../../ui/icons/TickIcon";
import Input from "../../ui/molecules/input/Input";

export function GestionePrelieviEdit() {
    const [selectionFlag, setSelectionFlag] = useState<string>('MULTIPLE')
    const [selectedEnvelopes, setSelectedEnvelopes] = useState<OptionType[]>([]);
    const [foundEnvelope, setFoundEnvelope] = useState<boolean>(false)

    useEffect(() => {
        if(selectionFlag === 'MULTIPLE') {
            setFoundEnvelope(selectionFlag !== 'MULTIPLE')
        }
        else {
            setSelectedEnvelopes([])
        }
    }, [selectionFlag])

    return (
        <div>
            <div className="prelievi-container">
                {/* HEADER */}
                <div className='prelievi-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Buste Prelievo Campioni </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> Modifica</span>
                    </div>
                </div>

                {/* CONTENT */}
                <div style={{padding: '24px 24px 32px 24px'}}>

                    {/* MULTIPLE ENVELOPES */}
                    <div className='mb-5' style={{display: 'flex', gap: '8px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <CustomRadio
                                options={[{ label: '', value: 'MULTIPLE' }]}
                                selectedValue={selectionFlag}
                                onChange={(value) => setSelectionFlag(value)}
                                id={'multiple'}
                                name={'multiple'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{color: '#5a8388'}}>Modifica Stato Buste Prelievo Campioni del Tecnico Ispettore</span>
                        </div>
                    </div>

                    <div className="ml-20">
                        <div className="pb-3" style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                            <div style={{flex: '0.5'}}>
                                <div className="text-left">
                                    <span className="input-label">Tecnico Ispettore</span>
                                </div>
                                <SelectCustom
                                    // error={}
                                    disabled={selectionFlag === 'SINGLE'}
                                    errorLabel='Ispettore mancante'
                                    placeholder={'Seleziona ispettore...'}
                                    options={[{value: '1', label: 'Alessandro Lo Giudice'}, 
                                        {value: '2', label: 'Isidoro Spanò'}]}
                                    onChange={(value) => {}}
                                />
                            </div>
                            <div style={{flex: '0.5'}}>
                                <div className="text-left">
                                    <span className="input-label">Stato Buste Prelievo Campione</span>
                                </div>
                                <SelectCustom
                                    // error={}
                                    disabled={selectionFlag === 'SINGLE'}
                                    errorLabel='Stato mancante'
                                    placeholder={'Seleziona stato...'}
                                    options={[{value: '1', label: 'In Giacenza'}, 
                                        {value: '2', label: 'Consegnata'},
                                        {value: '3', label: 'Smarrita'},
                                        {value: '4', label: 'Annulata/Distrutta'}
                                    ]}
                                    onChange={(value) => {}}
                                />
                            </div>
                        </div>

                        <div className="pb-5" style={{borderBottomWidth: '1px'}}>
                            <div className="text-left">
                                <span className="input-label">Elenco Buste Prelievo Campione del Tecnico Ispettore</span>
                            </div>
                            <MultiSelectCheckbox
                                // error={}
                                disabled={selectionFlag === 'SINGLE'}
                                errorLabel="Non è stata selezionata alcuna busta!"
                                onChange={(e) => {
                                    setSelectedEnvelopes(e.value)
                                    // DISPATCH
                                }}
                                value={selectedEnvelopes}
                                option={[{value: '00000100', label: '00000100'}, {value: '00000101', label: '00000101'}, {value: '00000102', label: '00000102'}]}
                                placeholder=""
                                selectAllLabel="Tutte"
                            />
                        </div>

                        {
                            selectedEnvelopes.length > 0 && (
                                <div className="pb-3 pt-3" style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'flex-end'}}>
                                    <div style={{flex: '0.5'}}>
                                        <div className="text-left">
                                            <span className="input-label">Nuovo Stato</span>
                                        </div>
                                        <SelectCustom
                                            // error={}
                                            disabled={selectionFlag === 'SINGLE'}
                                            errorLabel='Stato mancante'
                                            placeholder={'Seleziona nuovo stato...'}
                                            options={[{value: '1', label: 'In Giacenza'}, 
                                                {value: '2', label: 'Consegnata'},
                                                {value: '3', label: 'Smarrita'},
                                                {value: '4', label: 'Annulata/Distrutta'}
                                            ]}
                                            onChange={(value) => {}}
                                        />
                                    </div>
                                    <Button
                                        disabled={selectionFlag === 'SINGLE'}
                                        iconPosition='left'
                                        icon={<TickIcon />}
                                        variant='outline'
                                        color='green'
                                        label='Assegna Nuovo Stato'
                                        size='lg' 
                                        />
                                </div>
                            )
                        }
                    </div>

                    {/* SINGLE ENVELOPE */}
                    <div className='mb-5 mt-10' style={{display: 'flex', gap: '8px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <CustomRadio
                                options={[{ label: '', value: 'SINGLE' }]}
                                selectedValue={selectionFlag}
                                onChange={(value) => setSelectionFlag(value)}
                                id={'single'}
                                name={'single'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{color: '#5a8388'}}>Modifica Stato Busta Prelievo Campioni</span>
                        </div>
                    </div>

                    <div className="ml-20">
                        <div className="pb-3">
                            <div className="text-left">
                                    <span className="input-label">Busta Prelievo Campioni n.</span>
                            </div>
                            <Input
                                disabled={selectionFlag === 'MULTIPLE'}
                                placeholder="Specifica n. busta..."
                                onChangeText={(text) => {
                                    setFoundEnvelope(text !== '')
                                }}
                            />
                        </div>

                        {
                            foundEnvelope && (
                                <div style={{display: 'flex', gap: '24px', alignItems: 'flex-end'}}>
                                    <div style={{flex: '0.5'}}>
                                        <div className="text-left">
                                            <span className="input-label">Stato</span>
                                        </div>
                                        <SelectCustom
                                            // error={}
                                            errorLabel='Stato mancante'
                                            placeholder={'Seleziona nuovo stato...'}
                                            options={[{value: '1', label: 'In Giacenza'}, 
                                                {value: '2', label: 'Consegnata'},
                                                {value: '3', label: 'Smarrita'},
                                                {value: '4', label: 'Annulata/Distrutta'}
                                            ]}
                                            onChange={(value) => {}}
                                        />
                                    </div>
                                    <Button
                                        iconPosition='left'
                                        icon={<TickIcon />}
                                        variant='outline'
                                        color='green'
                                        label='Assegna Nuovo Stato'
                                        size='lg' 
                                        />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}