/* PAC */
export interface PACDTO {
    id?: string
    year?: string
    norm?: string
    note?: string
    startingDate?: Date
    endingDate?: Date
    userId?: string
}

export interface PACErrorsDTO {
    year: boolean
    norm: boolean
    startingDate: boolean
    endingDate: boolean
}

export interface GetAllPACsDTO {
    total: number,
    page: number,
    perPage: number | null,
    prev: string | null,
    next: string | null,
    totalPage: number | null,
    data: PACDTO[]
}

export interface GetAllPACsFiltersDTO {
    page: number
    itemsPerPage: number
    order?: boolean
    sort?: string
}

/* PAC DETAILS */

export interface PACDetailCreationDTO {
    periodicity: number[]
    mode?: PACVisitingModeEnum
    visitType?: PACVisitingTypeEnum
    sampling?: boolean
    specifications: string
    note?: string
    customerId: string
    annualControlPlaneId: string
}

export interface PACDetailUpdateDTO {
    periodicity?: number[]
    mode?: PACVisitingModeEnum
    visitType?: PACVisitingTypeEnum
    sampling?: boolean
    specifications?: string
    annualControlPlaneId?: string
    note?: string
}

export interface PACDetailErrors {
    periodicity: boolean
    mode: boolean
    visitType: boolean
    sampling: boolean
    annualControlPlaneId: boolean
}

export interface PACDetailDTO {
    id: string
    periodicity: number[]
    mode: PACVisitingModeEnum
    visitType: PACVisitingTypeEnum
    sampling: boolean
    specifications: string
    note: string
    annualControlPlaneDTO: PACDTO
    customerDTO: {
        id: string
        companyName: string
        headQuarters: string[]
    }
}

export interface GetAllPACDetailsDTO {
    total: number,
    page: number,
    perPage: number | null,
    prev: string | null,
    next: string | null,
    totalPage: number | null,
    data: PACDetailDTO[]
}

export interface GetAllPACDetailsFiltersDTO {
    page: number
    itemsPerPage: number
    order?: boolean
    sort?: string
    customerId?: string
    country?: string
    head_quarter?: string
    periodicity?: number[]
    norm?: string
    year?: number
}


export enum PACVisitingModeEnum {
    CON_PREAVVISO = 'WITH_FOREWARNING',
    SENZA_PREAVVISO = 'WITHOUT_FOREWARNING'
}

export enum PACVisitingTypeEnum {
    ORDINARY_CHECK = 'ORDINARY_CHECK',
    ANNUAL_CHECK = 'ANNUAL_CHECK',
    ADDITIONAL_CONTROL = 'ADDITIONAL_CONTROL',
    EXTRAORDINARY_CONTROL = 'EXTRAORDINARY_CONTROL',
    TARGETED_CONTROL = 'TARGETED_CONTROL',
    STARTUP_PHASE_CONTROL = 'STARTUP_PHASE_CONTROL'
}

