import { useNavigate } from "react-router-dom"
import { Layout } from "../../../../ui/layout"
import Button from "../../../../ui/molecules/button"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { GestionePACList } from "./list"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { useEffect, useState } from "react"
import { resetPACCreationStatus, resetPACUpdateStatus } from "../slice"

export function GestionePAC() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const pacState = useAppSelector(state => state.pac)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (pacState.PACCreationStatus === 'successfully') {
            dispatch(resetPACCreationStatus())
            setShowSuccess(true)
        }
        if(pacState.PACUpdateStatus === 'successfully') {
            dispatch(resetPACUpdateStatus())
            setShowSuccess(true)
        }
    }, [pacState.PACCreationStatus, pacState.PACUpdateStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione', 'Gestione PAC']}
            noPaddingLat
            headerLabel="Gestione PAC"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Crea nuovo" color={"orange"}
                        onClick={() => {
                            navigate('/addPac')
                        }}
                    />

                </div>
            }
        >
            {
                showSuccess &&
                <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
            <GestionePACList />
        </Layout>
    )
}