import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import Button from "../../../../ui/molecules/button"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Banner } from "../../../../ui/organisms/banner"
import { EditPACForms } from "./forms"
import { PacDetailUpdate, PACDetailValidation, resetPACDetailValidationStatus } from "../slice"
import { useEffect } from "react"

export function EditPAC() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const pacState = useAppSelector(state => state.pac)

    useEffect(() => {
        if(pacState.PACDetailValidationStatus === 'successfully') {
            dispatch(resetPACDetailValidationStatus())
            dispatch(PacDetailUpdate({body: pacState.PACDetailUpdateRequest, id: pacState.PACDetailIdToUpdate}))
        }
    }, [pacState.PACDetailValidationStatus])

    useEffect(() => {
        if(pacState.PACDetailUpdateStatus === 'successfully') {
            navigate('/pac')
        }
    }, [pacState.PACDetailUpdateStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Controllo', 'PAC', 'Modifica PAC']}
            headerLabel={`Modifica Dettaglio PAC - ${pacState.PACDetailClient}`}
            rightBar={true}
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/pac')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(PACDetailValidation(pacState.PACDetailUpdateRequest))
                        }}
                    />
                </div>
            }
        >
            <div>
                <EditPACForms />
            </div>
        </Layout>
    )
}