import { useNavigate } from "react-router-dom"
import { EditIcon } from "../../../../ui/icons/edit"
import { formatterDate, normativa, optionsFormatter } from "../../../../utils"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { useEffect } from "react"
import { GetAllPac, setPACIdToUpdate, setPACUpdateEndingDate, setPACUpdateNorm, setPACUpdateNote, setPACUpdateStartingDate, setPACUpdateYear } from "../slice"
import { PACDTO } from "../dataService/dto"

export function GestionePACList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const pacState = useAppSelector(state => state.pac)

    useEffect(() => {
        dispatch(GetAllPac(pacState.getAllPACsFilters))
    }, [])

    const onEditHandle = (pac: PACDTO) => {
        dispatch(setPACUpdateYear(pac.year))
        dispatch(setPACUpdateNorm(pac.norm))
        dispatch(setPACUpdateStartingDate(pac.startingDate))
        dispatch(setPACUpdateEndingDate(pac.endingDate))
        dispatch(setPACUpdateNote(pac.note))
        dispatch(setPACIdToUpdate(pac.id))
        navigate('/editPac')
    }

    return (
        <div style={{padding: '24px'}}>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}></th>
                        <th>Anno</th>
                        <th>Normativa</th>
                        <th>Note</th>
                        <th>Data inizio</th>
                        <th>Data fine</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        pacState.getAllPACsResponse?.data.map((pac, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>
                                    <div style={{display: 'flex', gap: '6px'}}>
                                        <button onClick={() => onEditHandle(pac)}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                    </div>
                                </td>
                                <td>{pac.year}</td>
                                <td>{optionsFormatter(pac.norm!, normativa)}</td>
                                <td>{pac.note}</td>
                                <td>{formatterDate(pac.startingDate)}</td>
                                <td>{formatterDate(pac.endingDate)}</td>
                                <td></td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}