import { useState } from 'react'
import { RoundedPlusIcon } from '../../ui/icons/RoundedPlusIcon'
import Button from '../../ui/molecules/button'
import Input from '../../ui/molecules/input/Input'
import './styles.scss'
import { RoundedCloseIcon } from '../../ui/icons/CloseIcon'
import { SelectCustom } from '../../ui/molecules/select'
import MultiSelectCheckbox from '../../ui/molecules/multiSelectCheckbox'
import { OptionType } from '../../utils'
import { ProtocolSearch } from '../../ui/molecules/ProtocolSearch'
import TextArea from '../../ui/molecules/textArea'
import { EnvelopeIcon } from '../../ui/icons/EnvelopeIcon'
import { DocumentIcon } from '../../ui/icons/documentIcon'

export function GestionePrelieviLoadAssign() {
    const [loadedEnvelopes, setLoadedEnvelopes] = useState<boolean>(true)
    const [selectedEnvelopes, setSelectedEnvelopes] = useState<OptionType[]>([]);

    return (
        <div>
            <div className="prelievi-container">
                {/* HEADER */}
                <div className='prelievi-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Buste Prelievo Campioni </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> Carica</span>
                    </div>
                </div>

                {/* CONTENT */}
                <div style={{padding: '24px 24px 32px 24px'}}>

                    <div className='text-left'>
                        <span className='subtitle' style={{ color: '#314d51' }}>Carica nuove Buste Prelievo Campioni</span>
                    </div>
                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'flex-end'}}>
                        <div style={{flex: '0.33'}}>
                            <div className="text-left">
                                <span className="input-label">Dal n.</span>
                            </div>
                            <Input
                                placeholder="Specifica n. busta di partenza..."
                                onChangeText={(text) => {}}
                            />
                        </div>
                        <div style={{flex: '0.33'}}>
                            <div className="text-left">
                                <span className="input-label">Al n.</span>
                            </div>
                            <Input
                                placeholder="Specifica n. busta di arrivo..."
                                onChangeText={(text) => {}}
                            />
                        </div>
                        <div style={{flex: '0.33', display: 'flex', alignItems: 'flex-end'}}>
                            <Button
                                onClick={() => setLoadedEnvelopes(true)}
                                iconPosition='left'
                                label='Aggiungi Buste'
                                icon={<RoundedPlusIcon />}
                                variant='solid'
                                color='orange'
                                size='lg'
                                />
                        </div>
                    </div>

                    {
                        loadedEnvelopes && (
                            <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="prelievi-vertical-line" />
                                <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                    <div className="text-left">
                                        <div className="prelievi-bold-text">Dal n. </div>
                                        <div className="prelievi-text">00000108</div>
                                    </div>
                                    <div className="text-left">
                                        <div className="prelievi-bold-text">Al n.</div>
                                        <div className="prelievi-text">00000130</div>
                                    </div>
                                    <div className="text-left">
                                        <div className="prelievi-bold-text">Stato</div>
                                        <div className="prelievi-text">In Giacenza</div>
                                    </div>
                                    <button onClick={() => setLoadedEnvelopes(!loadedEnvelopes)}>
                                        <RoundedCloseIcon color="#166428" />
                                    </button>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>

            <div className="prelievi-container">
                {/* HEADER */}
                <div className='prelievi-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Buste Prelievo Campioni </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> ASSEGNA</span>
                    </div>
                </div>

                {/* CONTENT */}
                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div className="text-left">
                        <span className="input-label">Tecnico Ispettore</span>
                    </div>
                    <SelectCustom
                        // error={}
                        errorLabel='Ispettore mancante'
                        placeholder={'Seleziona ispettore...'}
                        options={[{value: '1', label: 'Alessandro Lo Giudice'}, 
                            {value: '2', label: 'Isidoro Spanò'}]}
                        onChange={(value) => {}}
                    />

                    <div className='pt-5 pb-5' style={{borderBottomWidth: '1px'}}>
                        <div className="text-left">
                            <span className="input-label">Elenco Buste per prelievo campioni da assegnare</span>
                        </div>
                        <MultiSelectCheckbox
                            // error={}
                            errorLabel="Non è stata selezionata alcuna busta!"
                            onChange={(e) => {
                                setSelectedEnvelopes(e.value)
                                // DISPATCH
                            }}
                            value={selectedEnvelopes}
                            option={[{value: '00000100', label: '00000100'}, {value: '00000101', label: '00000101'}, {value: '00000102', label: '00000102'}]}
                            placeholder="Buste da assegnare"
                            selectAllLabel="Tutte"
                        />
                    </div>

                    <div className='text-left pt-5 pb-3'>
                        <span className='subtitle' style={{ color: '#314d51' }}>Protocollo Invio</span>
                    </div>
                    <div className='pb-5' style={{borderBottomWidth: '1px'}}>
                        <ProtocolSearch
                            type='departure'
                            onSelect={() => {}} 
                            />
                    </div>
                    
                    <div className='pb-5'>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea onChangeText={(value) => {}} />
                    </div>

                    <div style={{display: 'flex', gap: '24px', alignItems: 'center'}}>
                        <Button
                            iconPosition='left'
                            icon={<EnvelopeIcon />}
                            variant='solid'
                            color='lightGreen'
                            label='Assegna Buste Prelievo Campioni'
                            size='lg' 
                            />
                        <Button
                            iconPosition='left'
                            icon={<DocumentIcon color='#fff' />}
                            variant='outline'
                            color='blue'
                            label='Stampa Lettera di Consegna'
                            size='lg' 
                            />
                    </div>
                </div>
            </div>
        </div>
    )
}