import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { Calendar } from "../../../../../ui/molecules/calendar";
import { SelectCustom } from "../../../../../ui/molecules/select";
import TextArea from "../../../../../ui/molecules/textArea";
import { yearOptions, normativa } from "../../../../../utils";
import { setPACCreationEndingDate, setPACCreationNorm, setPACCreationNote, setPACCreationStartingDate, setPACCreationYear, setPACUpdateEndingDate, setPACUpdateNorm, setPACUpdateNote, setPACUpdateStartingDate, setPACUpdateYear } from "../../slice";

export function GestionePACEditForm() {
    const dispatch = useAppDispatch()
    const pacState = useAppSelector(state => state.pac)

    return (
        <div>
            <div className="pac-container">

                <div className='pac-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Gestione PAC </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Modifica</span>
                    </div>
                </div>

                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Anno</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACErrors.year}
                                errorLabel='Anno mancante'
                                value={String(pacState.PACUpdateRequest.year)}
                                placeholder={'Seleziona anno...'}
                                options={yearOptions}
                                onChange={(value) => dispatch(setPACUpdateYear(Number(value)))}
                            />
                        </div>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Normativa</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACErrors.norm}
                                errorLabel='Normativa mancante'
                                value={pacState.PACUpdateRequest.norm}
                                placeholder={'Seleziona normativa...'}
                                options={normativa}
                                onChange={(value) => dispatch(setPACUpdateNorm(value))}
                            />
                        </div>
                    </div>

                    <div className='pb-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Data inizio</span>
                            </div>
                            <Calendar
                                errorLabel={pacState.PACErrors.startingDate ? 'Data inizio mancante' : undefined}
                                selected={pacState.PACUpdateRequest.startingDate}
                                onChange={(date) => dispatch(setPACUpdateStartingDate(date))} 
                                />
                        </div>
                        <div style={{flex: '0.5'}}>
                        <div className="text-left">
                                <span className="input-label">Data fine</span>
                            </div>
                            <Calendar
                                errorLabel={pacState.PACErrors.startingDate ? 'Data fine mancante' : undefined}
                                minDate={pacState.PACUpdateRequest.startingDate}
                                selected={pacState.PACUpdateRequest.endingDate}
                                onChange={(date) => dispatch(setPACUpdateEndingDate(date))}  
                                />
                        </div>
                    </div>

                    <div className='pb-5 pt-5'>
                        <div className="text-left mt-3">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={pacState.PACUpdateRequest.note}
                            onChangeText={(value) => dispatch(setPACUpdateNote(value))} />
                    </div>
                </div>
            </div>
        </div>
    )
}