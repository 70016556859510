import axios from "axios";
import { GetAllPACDetailsDTO, GetAllPACDetailsFiltersDTO, GetAllPACsDTO, GetAllPACsFiltersDTO, PACDetailCreationDTO, PACDetailDTO, PACDetailUpdateDTO, PACDTO } from "./dto";
import { PACService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import qs from "qs";

export class PACServiceImpl implements PACService {

    /* PAC */
    public CreatePAC(data: PACDTO): Promise<void> {
        return axios.post("/api/annual-control-plane",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdatePAC(data: PACDTO, id: string): Promise<void> {
        return axios.put("/api/annual-control-plane/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllPACs(filters: GetAllPACsFiltersDTO): Promise<GetAllPACsDTO> {
        return axios.get("/api/annual-control-plane", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetPACById(id: string): Promise<PACDTO> {
        return axios.get("/api/annual-control-plane/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    /* PAC DETAIL */
    public CreatePACDetail(data: PACDetailCreationDTO): Promise<void> {
        return axios.post("/api/annual-control-plane-detail",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdatePACDetail(data: PACDetailUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/annual-control-plane-detail/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeletePACDetail(id: string): Promise<void> {
        return axios.delete("/api/annual-control-plane-detail/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllPACDetails(filters: GetAllPACDetailsFiltersDTO): Promise<GetAllPACDetailsDTO> {
        return axios.get("/api/annual-control-plane-detail", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                id: filters.customerId,
                countryRegion: filters.country === '' ? undefined : filters.country,
                headQuarter: filters.head_quarter,
                year: filters.year,
                norm: filters.norm,
                periodicity: filters.periodicity
            },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetPACDetailById(id: string): Promise<PACDetailDTO> {
        return axios.get("/api/annual-control-plane-detail/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}