import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import Button from "../../../../ui/molecules/button"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Banner } from "../../../../ui/organisms/banner"
import { AddPACForms } from "./forms"
import { PacDetailCreation, PACDetailValidation, resetPACDetailValidationStatus } from "../slice"
import { useEffect } from "react"

export function AddPAC() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const pacState = useAppSelector(state => state.pac)

    useEffect(() => {
        if(pacState.PACDetailValidationStatus === 'successfully') {
            dispatch(resetPACDetailValidationStatus())
            dispatch(PacDetailCreation(pacState.PACDetailCreationRequest))
        }
    }, [pacState.PACDetailValidationStatus])

    useEffect(() => {
        if(pacState.PACDetailCreationStatus === 'successfully') {
            navigate('/pac')
        }
    }, [pacState.PACDetailCreationStatus])

    if(sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Controllo', 'PAC', 'Nuovo PAC']}
                headerLabel="Nuovo Dettaglio PAC"
                rightBar={true}
                headerIcon={
                    <button style={{paddingTop: '12px'}} onClick={() => navigate('/pac')}>
                        <ArrowLeftIcon />
                    </button>
                }
                headerChildren={
                    <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                            onClick={() => {
                                dispatch(PACDetailValidation(pacState.PACDetailCreationRequest))
                            }}
                        />
                    </div>
                }
            >
                <div>
                    <AddPACForms />
                </div>
            </Layout>
        )
    }

    return (
        <Banner
            type="error"
            visible={!sessionState.selected.customerId}
            label={"Devi prima selezionare un cliente per accedere all'area"}
            closeAction={function (): void {
                alert("Devi prima selezionare un cliente per accedere all'area")
            }}
        />
    )
}