import { Layout } from "../../ui/layout"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { GestionePrelieviList } from './list'

export function GestionePrelieviForAll() {
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione Prelievi', 'Consultazione']}
            noPaddingLat
            headerLabel="Gestione Prelievi"
        >
            <div className="fieldset bg-brandPrimary-200" style={{ padding: '24px' }}>
                <GestionePrelieviList />
            </div>
        </Layout>
    )
}