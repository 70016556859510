import { GetAllPACDetailsDTO, GetAllPACDetailsFiltersDTO, GetAllPACsDTO, GetAllPACsFiltersDTO, PACDetailCreationDTO, PACDetailDTO, PACDetailUpdateDTO, PACDTO } from "./dto";
import { PACServiceImpl } from "./serviceimpl";

export interface PACService {
    CreatePAC(data: PACDTO): Promise<void>
    UpdatePAC(data: PACDTO, id: string): Promise<void>
    GetAllPACs(filters: GetAllPACsFiltersDTO): Promise<GetAllPACsDTO>
    GetPACById(id: string): Promise<PACDTO>
    
    CreatePACDetail(data: PACDetailCreationDTO): Promise<void>
    UpdatePACDetail(data: PACDetailUpdateDTO, id: string): Promise<void>
    DeletePACDetail(id: string): Promise<void>
    GetAllPACDetails(filters: GetAllPACDetailsFiltersDTO): Promise<GetAllPACDetailsDTO>
    GetPACDetailById(id: string): Promise<PACDetailDTO>
}

export function NewPACService(): PACService {
    return new PACServiceImpl()
}