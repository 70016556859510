import clsx from 'clsx'
import { useNavigate } from "react-router-dom"
import { Layout } from "../../ui/layout"
import Button from "../../ui/molecules/button"
import { ClientBanner } from "../../ui/molecules/clientBanner"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { optionsFormatter, attivita_bio, normativa } from "../../utils"
import { RegistriAziendaliList } from "../gestioneClienti/registriAziendali/customerList"
import { GestionePrelieviLoadAssign } from "./loadAssign"
import { HorizontalTab } from "../../ui/organisms/horizontalTab"
import { useState } from "react"
import { Tab } from 'react-tabs'
import { GestionePrelieviEdit } from './edit'
import { GestionePrelieviList } from './list'

export function GestionePrelievi() {
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState<number>(0)

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione Prelievi']}
            noPaddingLat
            headerLabel="Gestione Prelievi"
        >
            <HorizontalTab
                children={[
                    <>
                        <Tab
                            onClick={() => {
                                setSelectedTab(0)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                    "text-label-sm text-neutral-600": selectedTab !== 0
                                })
                            }
                            tabIndex={"0"}
                        >
                            Carica/Assegna
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(1)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                }
                                )
                            }
                            tabIndex={"1"}
                        >
                            Modifica
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(2)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 2,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 2
                                }
                                )
                            }
                            tabIndex={"2"}
                        >
                            Consultazione
                        </Tab>
                    </>
                ]}
            />
            <div className="fieldset bg-brandPrimary-200" style={{ padding: '24px' }}>
                {
                    selectedTab === 0 &&
                    <GestionePrelieviLoadAssign />
                }
                {
                    selectedTab === 1 &&
                    <GestionePrelieviEdit />
                }
                {
                    selectedTab === 2 &&
                    <GestionePrelieviList />
                }
            </div>
        </Layout>
    )
}