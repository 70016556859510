import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../../ui/icons/arrow-left";
import { Layout } from "../../../../../ui/layout";
import { MenuItems } from "../../../../../ui/organisms/navbar/dto";
import Button from "../../../../../ui/molecules/button";
import { GestionePACAddForm } from "./forms";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { PacCreation, PACValidation, resetPACValidationStatus } from "../../slice";
import { useEffect } from "react";

export function GestionePACAdd() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const pacState = useAppSelector(state => state.pac)

    useEffect(() => {
        if(pacState.PACValidationStatus === 'successfully') {
            dispatch(resetPACValidationStatus())
            dispatch(PacCreation(pacState.PACCreationRequest))
        }
    }, [pacState.PACValidationStatus])

    useEffect(() => {
        if (pacState.PACCreationStatus === 'successfully') {
            navigate('/gestionePAC')
        }
    }, [pacState.PACCreationStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Controllo', 'PAC', 'Nuovo PAC']}
            headerLabel="Nuovo PAC"
            rightBar={true}
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/gestionePAC')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(PACValidation(pacState.PACCreationRequest))
                        }}
                    />
                </div>
            }
        >
            <div>
                <GestionePACAddForm />
            </div>
        </Layout>
    )
}