import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import { SelectCustom } from '../../../../ui/molecules/select'
import TextArea from '../../../../ui/molecules/textArea'
import { formatterDate, MultipleMonthOptions, normativa, optionsFormatter, OptionType, PACVisitingModeOptions, PACVisitingTypeOptions, SingleMonthOptions, yearOptions } from '../../../../utils'
import '../styles.scss'
import { GetAllPac, resetGetAllPACsStatus, resetPACDetailCreationRequest, setPACDetailCreationAnnualControlPlaneId, setPACDetailCreationCustomerId, setPACDetailCreationMode, setPACDetailCreationPeriodicity, setPACDetailCreationSampling, setPACDetailCreationSpecifications, setPACDetailCreationVisitingType, setPACDetailsCreationNote } from '../slice'

export function AddPACForms() {
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const pacState = useAppSelector(state => state.pac)
    const [pacOptions, setPacOptions] = useState<OptionType[]>([])

    useEffect(() => {
        dispatch(GetAllPac(pacState.getAllPACsFilters))
        dispatch(resetPACDetailCreationRequest())
        dispatch(setPACDetailCreationCustomerId(sessionState.selected.customerId))
    }, [])

    useEffect(() => {
        if(pacState.getAllPACsStatus === 'successfully' &&
            pacState.getAllPACsResponse !== undefined
        ) {
            dispatch(resetGetAllPACsStatus())
            const data = pacState.getAllPACsResponse.data.filter(pac => pac.norm === sessionState.selected.customerNorm).map(pac => {
                return {
                    value: pac.id,
                    label: `Anno rif. ${pac.year} - ${optionsFormatter(pac.norm!, normativa)} - Valido dal ${formatterDate(pac.startingDate)} al ${formatterDate(pac.endingDate)}`
                } as OptionType
            })
            setPacOptions(data)
        }
    }, [pacState.getAllPACsStatus])

    return (
        <div>
            <div className="pac-container">

                <div className='pac-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>PAC </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Scegli riferimento PAC</span>
                    </div>
                </div>

                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div>
                        <SelectCustom
                            error={pacState.PACDetailErrors.annualControlPlaneId}
                            errorLabel='Riferimento mancante'
                            placeholder={'Seleziona riferimento...'}
                            options={pacOptions}
                            onChange={(value) => dispatch(setPACDetailCreationAnnualControlPlaneId(value))}
                        />
                    </div>
                </div>
            
            </div>

            <div className="pac-container">

                <div className='pac-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>PAC </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Dettagli</span>
                    </div>
                </div>

                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Periodo</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.periodicity}
                                errorLabel='Periodicità mancante'
                                placeholder={'Seleziona periodo...'}
                                options={sessionState.selected.customerNorm === 'NORMA_BIO' ?
                                    SingleMonthOptions : MultipleMonthOptions
                                }
                                onChange={(value) => dispatch(setPACDetailCreationPeriodicity(value?.split('-').map(v => Number(v))))}
                            />
                        </div>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Modalità</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.mode}
                                errorLabel='Modalità mancante'
                                placeholder={'Seleziona modalità...'}
                                options={PACVisitingModeOptions}
                                onChange={(value) => dispatch(setPACDetailCreationMode(value))}
                            />
                        </div>
                    </div>

                    <div className='pb-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Tipo Visita</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.visitType}
                                errorLabel='Tipologia mancante'
                                placeholder={'Seleziona tipo...'}
                                options={PACVisitingTypeOptions}
                                onChange={(value) => dispatch(setPACDetailCreationVisitingType(value))}
                            />
                        </div>
                        <div style={{flex: '0.5'}}>
                            <div className="text-left">
                                <span className="input-label">Prelievo</span>
                            </div>
                            <SelectCustom
                                error={pacState.PACDetailErrors.sampling}
                                errorLabel='Prelievo mancante'
                                placeholder={'Seleziona prelievo...'}
                                options={[{value: 'Sì', label: 'Sì'}, {value: 'No', label: 'No'}]}
                                onChange={(value) => dispatch(setPACDetailCreationSampling(value === 'Sì'))}
                            />
                        </div>
                    </div>

                    <div className='pb-5 pt-5'>
                        <div className="text-left">
                            <span className="input-label">Specifiche</span>
                        </div>
                        <TextArea
                            onChangeText={(value) => dispatch(setPACDetailCreationSpecifications(value))} />
                        <div className="text-left mt-3">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            onChangeText={(value) => dispatch(setPACDetailsCreationNote(value))} />
                    </div>
                </div>
            </div>
        </div>
    )
}