import { useNavigate } from "react-router-dom"
import { Layout } from "../../../../../ui/layout"
import { ArrowLeftIcon } from "../../../../../ui/icons/arrow-left"
import { MenuItems } from "../../../../../ui/organisms/navbar/dto"
import Button from "../../../../../ui/molecules/button"
import { GestionePACEditForm } from "./forms"
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks"
import { PacUpdate, PACValidation, resetPACValidationStatus } from "../../slice"
import { useEffect } from "react"

export function GestionePACEdit() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const pacState = useAppSelector(state => state.pac)

    useEffect(() => {
        if(pacState.PACValidationStatus === 'successfully') {
            dispatch(resetPACValidationStatus())
            dispatch(PacUpdate({body: pacState.PACUpdateRequest, id: pacState.PACIdToUpdate}))
        }
    }, [pacState.PACValidationStatus])

    useEffect(() => {
        if (pacState.PACUpdateStatus === 'successfully') {
            navigate('/gestionePAC')
        }
    }, [pacState.PACUpdateStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Controllo', 'PAC', 'Modifica PAC']}
            headerLabel="Modifica PAC"
            rightBar={true}
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/gestionePAC')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(PACValidation(pacState.PACUpdateRequest))
                        }}
                    />
                </div>
            }
        >
            <div>
                <GestionePACEditForm />
            </div>
        </Layout>
    )
}