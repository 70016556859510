import React from "react";
import { colors } from "../../colors";
import { MenuItemsIconsMap, PathMenuItemMap } from "../navbar/dto";
import { BreadcrumbProps } from "./Breadcrumb.types";

const Breadcrumb: React.FC<BreadcrumbProps> = ({
    ...props
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            {MenuItemsIconsMap(PathMenuItemMap.get(props.path[0].toLowerCase())!, colors.neutral[500], 12)}
            {
                props.path.map((item, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                        <span className="text-text-md font-normal" style={{ color: index + 1 !== props.path.length ? '#8383AD' : '#E4E4F7', fontFamily: 'Roboto', fontWeight: '500' }}>
                            {item}
                        </span>
                        {
                            index + 1 !== props.path.length &&
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <line x1="5.09141" y1="15.0025" x2="11.4307" y2="1.40791" stroke="#ADADD6" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        }
                    </div>
                )
                )}
        </div>
    )
};

export default Breadcrumb;